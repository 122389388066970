<template>
  <div id="settlements-reminders">
    <div class="box-body">

      <input-type
        v-for="field in schema"
        :key="field.name"
        v-model="search"
        v-validate="{ rules: field.rules }"
        :data-vv-value-path="'value'"
        :data-vv-as="field.label2 ? field.label2 : field.label"
        :error-message="vErrors.first(field.name)"
        :label="field.label"
        :name="field.name"
        :required="field.rules.required"
      />
    </div>
    <template v-for="user in filteredUsersList">
      <div :key="user.uuid" class="appraiser-settlements-listing-item list-group-item col-xs-12 col-md-6">
        <p class="settlements-user-label">
          <span>{{user.label}}</span>
        </p>
        <div>
          <button class="zord-btn btn zord-btn-primary btn-primary delay-settlement-reminder-button" @click="(event) => setNotificationActiveStatus(event, user)">Odłóż przypomnienie</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

  import api from '../../../../api'
  import ErrorNotify from '../../../share/mixins/ErrorNotify'
  import InputType from '../../../share/form/type/InputType'

  export default {
    name: 'AppraisersListing.vue',
    components: { InputType },
    mixins: {
      ErrorNotify
    },
    data () {
      return {
        users: [],
        usersWithActiveSettlement: [],
        usersFlags: [],
        schema: [
          {
            name: 'search',
            label: 'Szukaj użytkownika',
            rules: { required: false }
          }],
        search: ''
      }
    },
    beforeMount () {
      this.getUsers()
    },
    computed: {
      filteredUsersList () {
        return this.users.filter(user => user.label.includes(this.search))
      }
    },
    methods: {
      async getUsers () {
        try {
          let { data } = await api.request('finance', 'get', '/users?format=reminder')
          this.users = data
        } catch (error) {
          this.errorNotify(error)
        }
      },
      async setNotificationActiveStatus (event, user) {
        try {
          await api.request('finance', 'put', `users/${user.uuid}`, {remindAt: new Date()})
          this.getUsers()
          this.$notify({
            type: 'success',
            text: `Odłożono przypomnienie użytkownika ${user.label} na 24 godziny`
          })
        } catch (error) {
          this.errorNotify(error)
        }
      }
    }
  }
</script>

<style scoped>
  #appraisers-listing input[type="checkbox"] + label:before {
    position: inherit;
  }
  #appraisers-listing input[type="checkbox"]:checked + label:before {
    position: inherit;
    left: 0px
  }
  .appraiser-settlements-listing-item {
    display: flex;
    justify-content: space-between;
  }

  .delay-settlement-reminder-button {
    height: 30px
  }

  #settlements-reminders .settlements-user-label {
    margin: auto 0;
  }

</style>
